@layer components {
  .customer input[type="text"], .customer .input {
    @apply border-blue-highlight rounded bg-white;
  }

  .customer input[type="text"]::placeholder {
    @apply text-blue-light;
  }

  .customer .input-blue::placeholder {
    @apply text-blue;
  }
  
  .customer .input-blue-light::placeholder {
    @apply text-blue-light;
  }

  .customer .btn {
    @apply text-base;
  }

  .customer label {
    @apply mb-2 text-blue-medium;
  }

  .customer .input-group {
    @apply flex border border-blue-highlight rounded bg-white h-12 items-center;
  }

  .customer .input-group .prepend {
    @apply pl-0;
  }

  .customer .input-group .append {
    @apply pr-0;
  }

  .customer .input-group > input {
    @apply border-none p-4 h-full;
  }

  .customer .input-group-append {
    @apply flex items-center justify-center w-20;

    position: initial;
  }

  .customer .input-group-prepend {
    @apply flex items-center justify-center w-20;

    position: initial;
  }

  .customer .input-group-text {
    @apply text-blue text-sm font-normal;
  }
}
