@layer components {
  a.btn {
    @apply inline-block no-underline;
  }

  .btn {
    @apply font-secondary font-bold text-center text-lg leading-4 py-4 px-6 border cursor-pointer;
  }

  .btn-small {
    @apply px-4 py-2 ml-2;
  }

  .btn-block {
    @apply w-full;
  }

  .btn-primary {
    @apply bg-pink-deep text-white rounded;
  }

  .btn-warning-salmon {
    @apply bg-warning-salmon text-white rounded border-none;
  }

  .btn-secondary {
    @apply bg-pink-light text-white rounded;
  }

  .btn-gold {
    @apply bg-gold border-gold text-deep-blue;
  }

  .btn-white {
    @apply bg-white border-pink-light text-pink-light;
  }

  .btn-transparent {
    @apply bg-white text-primary border-0;
  }

  .btn-outline-primary {
    @apply btn-primary border-white;
  }

  .btn-disabled {
    @apply opacity-30 text-white border-disabled cursor-not-allowed;
  }

  .btn-payment {
    @apply btn bg-pink-deep text-white btn-block rounded-full shadow-md mt-6;
  }

  .btn-download {
    @apply border-none font-primary bg-gold/20 text-gold rounded-full my-4 px-4 py-1;
  }

  .btn-social {
    @apply bg-pink-highlight text-pink-deep rounded-full p-4;
  }

  .bo .btn-white {
    @apply rounded;
  }

  .btn-select-item {
    @apply w-6 h-6 flex justify-center items-center border rounded-full border-pink-deep;
    background-image: url('icon_plus.svg');
    background-repeat: no-repeat;
    background-position: center;
  }

  .btn-select-item.active {
    @apply bg-pink-deep;
    background-image: url('icon_tick.svg');
  }

  .btn-select-item.active-disabled {
    @apply bg-disabled border-disabled;
    background-image: url('icon_tick.svg');
  }
}
