@layer components {
  .details-box {
    @apply rounded-md border border-blue-highlight bg-white mb-2 p-4;
  }

  .pay-home-box {
    @apply rounded-t-3xl border px-6 pt-8 pb-7 m-0 flex flex-col;
  }

  .pay-home-box .logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pay-home-box.with-logo {
    @apply justify-end h-79.5;
  }

  .pay-home-box.without-logo {
    @apply justify-end h-62;
  }

  .blured {
    @apply bg-blur-background;
    backdrop-filter: blur(6px);
  }

  .input-text {
    @apply text-deep-blue font-bold;
  }

  /* TICKET */
  .ticket {
    @apply text-left;
  }

  .ticket-title {
    @apply font-secondary mb-3;
  }

  .ticket-subtotal,
  .ticket-item {
    @apply text;
  }

  .ticket hr {
    @apply border-blue-highlight;
  }

  .ticket-total,
  .ticket-subtotal,
  .ticket-item {
    @apply flex justify-between;
  }

  .ticket-total {
    @apply text font-bold;
  }

  /* --- */

  /* BILL SPLITTER */

  .bo .bill-splitter-tabs {
    @apply flex mx-0 mt-0 mb-4 md:w-2/4;
  }

  .bo .bill-splitter-tabs .splitter-tab label {
    @apply leading-none text-deep-blue border border-blue-highlight cursor-pointer;
  }

  .bo .splitter-tab input:checked + label {
    @apply bg-blue-highlight;
  }

  .bill-splitter-content {
    @apply hidden;
  }

  .bill-splitter-tabs {
    @apply flex mx-2 mt-8 mb-3;
  }

  .bill-splitter-tabs .splitter-tab {
    @apply h-full w-1/2 text-center;
  }

  .bill-splitter-tabs .splitter-tab label {
    @apply !p-3 text-base text-pink-light font-medium border border-pink-light;
  }

  .bill-splitter-tabs .splitter-tab:first-child label {
    @apply rounded-l-full;
  }

  .bill-splitter-tabs .splitter-tab:last-child label {
    @apply rounded-r-full;
  }

  .splitter-tab input:checked + label {
    @apply bg-pink-light text-white;
  }

  .bill-how-many-text {
    @apply text-blue-light mb-5;
  }

  .bill-each-one-text {
    @apply font-medium mt-8 mb-4;
  }

  .custom-splitter-info {
    @apply flex text-left space-x-2;
  }

  .custom-splitter-label {
    @apply text-sm uppercase font-medium;
  }

  .custom-splitter-input {
    @apply h-14 flex p-4 my-3 border border-blue-highlight rounded-lg text-blue items-center;
  }

  .bill-splitter-modal {
    height: 100dvh;
    width: 100vw;
  }
  /* --- */

  /* TIPS */

  .tips-table {
    @apply h-14 my-4 mb-5 flex justify-center;
  }

  .tip-box {
    @apply w-1/3 h-full text-center;
  }

  .tip-box label {
    @apply h-full flex flex-col border-t border-b border-blue-highlight text-primary justify-center;
  }

  .tip-box .tip-amount {
    @apply text-base font-bold leading-5;
  }

  .tip-box .tip-percentage {
    @apply text-blue;
  }

  .tips-table .tip-box:first-child label {
    @apply rounded-l-lg border-l;
  }

  .tips-table .tip-box:nth-child(0n + 2) label {
    @apply border-l border-r;
  }

  .tips-table .tip-box:last-child label {
    @apply rounded-r-lg border-r;
  }

  .tips-table-modal .tip-box {
    @apply w-1/5 h-full text-center;
  }

  .tips-table-modal .tip-box label {
    @apply rounded border;
    padding: 10px !important;
  }

  .tips-table-modal .tip-box:last-child label .tip-amount {
    font-size: 0.875rem;
    line-height: 1rem;
    min-height: 2.55rem;
    margin: -1px;
  }

  .tips-table-modal .tip-box input:checked + label {
    @apply border-2 border-pink-deep;
    margin: -1px;
  }

  .tips-table .tip-box input:checked + label {
    @apply bg-pink-deep text-white;
  }

  .tips-table .tip-box input:checked + label > .tip-percentage {
    @apply text-white;
  }

  .custom-tip-box {
    @apply h-14 flex p-4 my-4 border border-blue-highlight rounded-lg text-blue items-center;
  }

  .custom-amount {
    @apply pl-1;
  }

  .custom-amount-input-with-value {
    @apply font-secondary !font-bold !text-lg !border-pink-deep !border-2;
  }
  /* --- */

  /* PAYMENT */
  .payment-btn {
    @apply btn-primary w-full mt-4 rounded-xl inline-flex justify-center divide-x items-center drop-shadow-lg;
  }

  .payment-btn .payment-action {
    @apply text-lg pr-3;
  }

  .payment-btn .payment-amount {
    @apply pl-3 font-normal;
  }

  .payment-apple-btn {
    @apply flex justify-between items-center bottom-0 mt-4 h-14 w-full bg-black rounded-xl text-white;
  }

  .payment-apple-btn .total-amount {
    @apply pl-7 pr-1.5 border-l border-white font-light;
  }
  /* --- */
}
/* TODO: Split the CSS by partials ? */
