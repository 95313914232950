@layer components {
  /* PAGE WRAPPER */
  .monitoring-wrapper {
    @apply font-primary-bo flex flex-col p-6 md:p-8 h-full;
  }
  /* --- */

  /* PAGE HEADER */
  .monitoring-header {
    @apply flex md:pb-5 justify-between;
  }

  .monitoring-header .title-wrapper {
    @apply hidden md:block font-bold;
  }

  .monitoring-header form {
    @apply flex-1 md:flex-none;
  }

  .monitoring-header .title {
    @apply font-secondary-bo pb-1;
    font-size: 32px;
    line-height: 32px;
  }

  .monitoring-header .subtitle {
    @apply text-lg;
  }

  .monitoring-header .owner-dropdown-wrapper {
    @apply flex justify-around w-5/12;
  }

  .monitoring-header .reporting-dropdown-wrapper {
    @apply justify-around w-5/12;
  }

  .monitoring-header .waiter-dropdown-wrapper {
    @apply hidden md:block;
  }
  /* --- */

  /* TABS */
  .monitoring-tabs {
    @apply md:hidden flex;
  }

  .monitoring-tabs span {
    @apply w-1/2 text-center border border-blue-highlight;
  }

  .monitoring-tabs label.active {
    @apply bg-blue-highlight;
  }

  .monitoring-tabs label {
    @apply text-primary font-bold m-0 !p-5 bg-white h-full w-full;
  }
  /* --- */

  /* TABLE */
  .monitoring-table {
    @apply md:bg-white md:px-6 md:py-4 rounded-lg;
  }
  /* --- */

  /* HEADERS AND COLUMNS */
  .desktop-header,
  .favorited-by-column {
    @apply hidden;
  }

  @media screen(md) {
    .desktop-header {
      @apply flex items-center w-full py-2 bg-white mb-1 text-font-grey text-sm;
    }

    .monitoring-table .row:nth-child(0n + 2) {
      @apply pt-8;
    }

    .tables-column {
      @apply w-64;
    }

    .favorited-by-column {
      @apply md:inline w-80;
    }

    .status-column {
      @apply w-72;
    }

    .amount-column {
      @apply w-72;
    }

    .amount-per-seat-column {
      @apply w-32;
    }

    .favorite-column {
      @apply w-32 flex justify-center;
    }
  }
  /* --- */

  /* ROWS */
  .monitoring-table .row {
    @apply flex items-center w-full bg-white my-1 py-2 no-underline;
  }

  @media screen(md) {
    .monitoring-table .row {
      @apply m-0 border-t border-grey-blue py-4;
    }
  }
  /* --- */

  /* CELLS */
  .monitoring-table .cell {
    @apply mx-2 md:m-0;
  }
  /* --- */

  /* The first and the last rows are rounded (on the top or the bottom) */
  .monitoring-table .row:nth-child(0n + 2) {
    @apply rounded-t-lg md:rounded-none;
  }

  .desktop-header {
    @apply rounded-t-lg;
  }

  .monitoring-table .row:last-child {
    @apply rounded-b-lg;
  }
  /* --- */

  /* Table number and text are colored by status : */
  .table-status.available {
    @apply bg-info md:bg-transparent md:text-info;
  }

  .table-status.in_progress {
    @apply bg-orange md:bg-transparent md:text-orange;
  }

  .table-status.unavailable {
    @apply bg-warning-salmon md:bg-transparent md:text-warning-salmon;
  }

  /* --- */

  .table-number-mobile {
    @apply text-primary text-center font-secondary-bo font-bold text-xl mx-1 mt-1 md:hidden;
  }

  .table-favorite {
    @apply flex md:justify-center items-center px-2 py-4 md:p-0;
  }

  .desktop-table-number {
    @apply hidden md:inline md:text-primary font-medium;
  }

  .table-status {
    @apply text-white text-xs font-bold rounded-lg px-2 py-1 md:text-base md:font-normal md:px-0 md:py-0;
  }

  .table-amount {
    @apply text-right flex-1 md:text-left md:flex-initial;
  }

  .table-amount-per-seat {
    @apply hidden md:inline md:text-left;
  }
}
