@layer components {
  html,
  body,
  .wrapper {
    @apply h-full;
  }

  body {
    @apply bg-grey-blue text-blue-deep;
  }

  .bo .wrapper {
    @apply flex flex-col antialiased h-screen md:flex-row;
  }

  .application .wrapper {
    @apply flex flex-col antialiased;
    height: 100dvh;
  }

  main {
    @apply overflow-y-auto w-full flex-1;
  }

  footer {
    @apply flex flex-col justify-center items-center p-4 bg-white text-primary;
  }

  hr {
    @apply mb-4 mt-4;
  }

  ul {
    @apply m-0;
  }

  .onboarding-wrapper {
    @apply min-h-screen px-4 flex flex-col justify-center items-center bg-grey-blue;
  }

  .onboarding-block {
    @apply p-12 w-full bg-white rounded-lg text-black;
  }

  .bo .page {
    @apply p-6;
  }

  .bo .page h1 {
    @apply font-bold text-deep-blue mb-5;
  }

  .bo .card {
    @apply w-full bg-white rounded-lg text-black p-4 mb-4;
  }

  .header_simple {
    @apply flex items-center p-4 text-primary;
  }

  .cardSlice {
    @apply w-full bg-white rounded text-blue-medium;
  }
}
