@layer components {
  .dropbtn {
    @apply input text-base font-normal leading-4 w-full flex items-center rounded;
    @apply hover:border-deep-blue hover:text-deep-blue peer-checked:border-deep-blue peer-checked:text-deep-blue;
    @apply bg-white py-7;
  }

  .dropbtn > .left-container {
    @apply flex-1;
  }

  .dropbtn .left-container .selected {
    @apply text-deep-blue font-bold;
  }

  .dropbtn .left-container .timepicker {
    @apply text-deep-blue font-bold p-0 h-auto border-none text-base;
  }

  .dropbtn .left-container .timepicker::placeholder {
    @apply text-deep-blue;
  }

  .dropbtn svg {
    @apply h-2 peer-checked:rotate-180 transition-all;
  }

  .dropdown-content {
    @apply hidden absolute left-0 right-0 peer-checked:block bg-white border border-grey-blue rounded mt-2 shadow-dropdown max-h-96 overflow-scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      @apply w-0;
    }
  }

  .dropdown-content label {
    @apply !p-4 m-0 text-deep-blue cursor-pointer;
  }

  .dropdown-content span:hover label,
  .dropdown-content span input:checked ~ label {
    @apply bg-grey-blue;
  }

  .dropdown-content input {
    @apply hidden;
  }

  .monitoring-header .dropdown {
    @apply flex-1 my-2 relative;
  }

  .monitoring-header .dropdown:first-child {
    @apply mr-2;
  }

  .monitoring-header .dropdown:last-child {
    @apply ml-2;
  }

  .bo .navbar .dropdown {
    @apply flex-1 relative;
  }
}
