@layer components {
  .bo .navbar {
    @apply flex justify-between items-center md:h-full md:w-80 md:p-0 md:bg-white md:block h-24 overflow-auto;
  }

  .bo header .available {
    @apply bg-success md:bg-white;
  }

  .bo header .in_progress {
    @apply bg-orange md:bg-white;
  }

  .bo header .unavailable {
    @apply bg-warning-salmon md:bg-white;
  }

  .bo .navbar .arrow-left {
    @apply pt-4;
  }

  .bo .navbar .title {
    @apply md:text-black md:p-6;
  }

  .bo .navbar .hook {
    @apply md:hidden;
  }

  .bo .navbar .menu {
    @apply absolute bg-white bottom-0 left-0 right-0 flex-col md:static md:block md:flex-1 max-h-full top-24;
  }

  .bo .navbar .menu ul {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      @apply w-0;
    }
  }

  .bo .navbar .menu .title {
    @apply sticky top-0 pb-4 md:hidden flex justify-between items-center;
  }

  .bo .navbar .menu .nav-menu-close {
    @apply float-right md:hidden;
  }

  .bo .navbar .mobile-nav-title {
    @apply md:hidden;
  }

  .bo .navbar .mobile-nav-title .subtitle {
    @apply text-sm opacity-80;
  }

  .bo .navbar .burger-cta {
    @apply flex-none m-0 md:hidden;
  }

  .bo .navbar .burger-cta > a {
    @apply block;
  }

  .bo .navbar .menu > li > hr {
    @apply md:block;
  }

  .bo .navbar .menu > li > a,
  .bo .navbar .menu > li > button {
    @apply block no-underline;
    @apply md:border-b-0;
  }

  .navbar {
    @apply bg-white p-6 text-blue-deep border-b border-medium-grey;
  }

  .navbar .icon {
    @apply text-blue-light;
  }

  .navbar.light {
    @apply bg-grey-blue text-blue-deep;
  }

  .navbar.light .icon {
    @apply text-blue-light;
  }

  .customer .navbar .mobile-nav-title {
    @apply flex text-center;
  }

  .navbar .title {
    @apply font-bold text-xl;
  }

  .navbar .hook {
    @apply text-base text-white opacity-50;
  }

  .navbar .menu {
    @apply hidden text-primary md:static md:block md:flex-1 md:max-h-full p-6;
  }

  .navbar .dropdown-wrapper {
    @apply mb-4 mt-8 md:mt-4;
  }

  #nav-menu-toggler {
    @apply hidden;
  }

  #nav-menu-toggler:checked ~ .menu {
    @apply flex z-10;
  }

  .navbar ul {
    @apply p-0 overflow-scroll md:!max-h-full;
  }

  .navbar .menu ul > li {
    @apply cursor-pointer rounded;
  }

  .navbar .menu ul > li.active,
  .navbar .menu ul > li:hover {
    @apply bg-pink-highlight;
  }


  .navbar .menu ul > li.active > a,
  .navbar .menu ul > li:hover > a,
  .navbar .menu ul > li.active > a > svg,
  .navbar .menu ul > li:hover > a > svg {
    @apply text-pink-deep;
  }

  .navbar .menu ul > li.logout > button > svg,
  .navbar .menu ul > li.logout.active > button > svg,
  .navbar .menu ul > li.logout:hover > button > svg {
    @apply text-pink-deep;
  }

  .navbar .menu ul > li > hr {
    @apply my-2;
  }

  .navbar .menu ul > li > a,
  .navbar .menu ul > li > button {
    @apply block px-3 py-4 text-black no-underline;
    &:hover {
      @apply text-pink-deep;
    }
  }

  .navbar .menu ul > li svg {
    @apply text-pink-deep inline-block mr-2 align-top w-6 h-6;
  }

  .navbar .menu ul > li:last-child > a {
    @apply border-b-0;
  }
}
