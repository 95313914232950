@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-Italic.ttf") format("truetype");
  font-style: italic;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("./fonts/Aeonik-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Aeonik";
  src: url("./fonts/Aeonik-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Maven Pro";
  src: url("./fonts/MavenPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Maven Pro";
  src: url("./fonts/MavenPro-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Maven Pro";
  src: url("./fonts/MavenPro-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans";
  src: url("./fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
}
