@layer components {
  .customer .wrapper {
    @apply flex flex-col;
    height: 100dvh;
  }

  .customer .page {
    @apply flex-1 px-5 pb-6 pt-4;
  }

  .customer .card {
    @apply rounded-md bg-white p-4 border border-blue-highlight;
  }

  .customer .page > .headings {
    @apply py-6;
  }

  .customer h1 {
    @apply mb-3;
  }

  .customer h1,
  .customer h3 {
    @apply text-blue-deep;
  }

  .customer h1 {
    @apply text-3xl text-center;
  }

  .customer ul li {
    @apply my-1;
  }

  .customer .subtitle {
    @apply text-base text-blue-medium font-secondary-bo;
  }

  .customer .amount {
    @apply float-right;
  }
}
