@layer components {
  .header-icon-left {
    @apply float-left mt-1 ml-2 w-6 h-6;
  }

  .header-icon-right {
    @apply float-right mt-1 mr-2 w-6 h-6;
  }

  .review-icon-star {
    @apply w-5 h-5 text-gold ml-2 -mt-1 inline-flex self-center;
  }

  .fav-icon {
    @apply text-secondary w-6;
  }

  .fav-icon.disabled {
    @apply text-disabled;
  }

  .fav-icon.nil {
    @apply text-white;
    stroke: #FFB555;
  }
}
