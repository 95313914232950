@layer components {
  /* TODO: check usefullness */
  label {
    @apply block mb-3 text-sm text-primary font-normal leading-4 !p-0;
  }

  label.required:before {
    @apply content-['*'];
  }

  input[type="text"],
  .input {
    @apply h-12 p-4 border border-grey text-deep-blue rounded-lg text-sm font-normal w-full focus:outline-none appearance-none;
  }

  textarea.input {
    @apply h-24;
  }

  input[type="text"]:focus-visible,
  .input:focus-visible {
    @apply outline-none border-deep-blue;
  }

  .error {
    @apply !border-warning-red;
  }

  .full-error {
    @apply mt-3 text-warning-red text-sm;
  }

  .hint {
    @apply mt-1 text-xs italic;
  }

  input[disabled="disabled"] {
    @apply btn-disabled;

    -webkit-text-fill-color: #fff;
  }

  .input-group {
    @apply relative;
  }

  .input-group-append {
    @apply absolute inset-y-0 right-3 flex items-center;
  }

  .input-group-prepend {
    @apply absolute inset-y-0 left-3 flex items-center;
  }

  .input-group-text {
    @apply text-deep-blue text-sm font-bold;
  }

  .boolean-switch-label {
    @apply !mb-0 cursor-pointer text-left;
  }

  /* TODO: Check where is the `.customer .boolean-switch-container` class comming from, because it adds a padding on the boolean-switch-container class */
  .boolean-switch-container {
    @apply block bg-pink-lighter w-10 h-5 rounded-full !p-0;
  }

  .boolean-switch-circle {
    @apply absolute left-0.5 top-0.5 bg-white w-4 h-4 rounded-full transition;
  }

  .boolean-switch input:checked ~ .boolean-switch-circle {
    @apply transform translate-x-full left-1.5;
  }

  .boolean-switch input:checked ~ .boolean-switch-container {
    @apply bg-pink-deep;
  }

  .input-counter {
    @apply flex justify-center font-secondary;
  }

  .input-counter .input-counter-action {
    @apply flex bg-pink-light bg-opacity-20 w-8 h-8 rounded-full cursor-pointer outline-none;
  }

  .input-counter .input-counter-action:disabled {
    opacity: .5;
  }

  .input-counter .input-counter-action span {
    @apply m-auto font-thin text-3xl leading-8 text-pink-deep;
  }

  .input-counter .input-counter-field {
    @apply text-center text-2xl font-bold w-full;
  }

  input[type="number"]::-webkit-inner-spin-button,
  form input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }

  .input-counter input:focus,
  .input-counter button:focus {
    @apply outline-none;
  }

  select.select {
    @apply bg-white !h-full appearance-none;
  }

  .select-arrow {
    @apply pointer-events-none absolute inset-y-0 right-0 flex pt-10 pr-8 text-grey;
  }

  /* SEE MORE - SEE LESS BUTTON */
  .more-or-less-btn {
    @apply w-28 py-1 mt-3 flex items-center justify-center rounded-full bg-secondary/20 text-secondary font-medium;
  }

  .see-more-btn {
    @apply bg-pink-light text-white;
  }

  .see-less-btn {
    @apply bg-pink-light text-white;
  }
  /* --- */

  /* SUCCESS CHECK */
  .success-check-wrapper {
    @apply w-7 h-7 mt-0.5 text-white flex flex-none items-center justify-center bg-success rounded-full;
  }
  /* --- */

  /* 5 STARS RATING */
  .star-rating {
    @apply flex flex-row-reverse justify-center;
  }

  .star-rating input {
    @apply hidden;
  }

  .star-rating label {
    @apply !px-2;
  }

  .star-rating input:checked ~ label > svg {
    @apply text-gold;
  }
  /* --- */
}
